<template>
  <div class="collect_detail">
    <div class="detail_top">


      <div class="deatil_flex">


        <div class="right_detail_text">
          <div class="relic_name">
            <h2>
              {{ list.relic_name }}
            </h2>

          </div>

          <div class="detail-row">
            <p v-if="list && list.name_old">
              原&emsp;&emsp;名:
              <span class="item-value">{{
                list.name_old
              }}</span>
            </p>

            <p>
              年&emsp;&emsp;代:
              <span class="item-value">{{ list.year }}</span>

            </p>



            <p style="margin-left: 40px">
              类&emsp;&emsp;别:
              <span class="item-value">{{
                list.classes
              }}</span>
            </p>
          </div>


          <div class="left_current_img">
            <div class="infor_img">
              <div class="bannerBox">
                <viewer :images="list.relic_img">
                  <div class="swiper-container gallery_top">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide" v-for="(item, index) of list.relic_img" :key="index">
                        <el-image class="gallery_topimg1" :src="item.file"> </el-image>
                      </div>
                    </div>
                  </div>
                </viewer>

                <div style="height: 12px; width: 400px"></div>

              </div>
            </div>

            <div class="down-button" @click="openLogin"> 下载高清原图</div>

          </div>
        </div>
      </div>
    </div>

    <div class="login-box" v-if="showLogin" @click="closeLogin">
      <div class="login-head" @click.stop="noClose" :style="{ 'background': config.theme_color }">
        <div class="head-item " :class="loginType == '1' ? 'active' : ''" @click="changeType('1')">登录</div>
        <div class="head-item" :class="loginType == '2' ? 'active' : ''" @click="changeType('2')">注册</div>
      </div>
      <div class="login-body" @click.stop="noClose">
        <div class="login-row">
          <div class="login-input">
            <input type="text" v-model="name" placeholder="账号">
          </div>
          <div class="login-input">
            <input type="password" v-model="password" placeholder="密码">
          </div>
          <div class="login-input">
            <input class="code-input" v-model="code" type="text" placeholder="验证码">
            <img class="code-img" :src="codeUrl" @click="changeVerify" alt="">
          </div>
          <div class="login-input">
            <div class="login-button" v-if="loginType == '1'" @click="login" :style="{ 'background': config.theme_color }">
              登录
            </div>
            <div class="login-button" v-else @click="register" :style="{ 'background': config.theme_color }">
              注册
            </div>


          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, getCurrentInstance, ref, reactive } from "vue";
import { useRouter } from "vue-router";
import { ElNotification } from "element-plus";
import "swiper/dist/css/swiper.min.css";
import "swiper/dist/js/swiper.min";
import Swiper from "swiper";

import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { DDSLoader } from 'three/examples/jsm/loaders/DDSLoader.js';
const param = {};
let scene = {};
export default {
  name: "CollectDetail",

  data() {
    return {
      num: 1,
      galleryThumbs: {},
      // showLogin:false,

      // loginType:'1',
      images: [
        "https://picsum.photos/200/200",
        "https://picsum.photos/300/200",
        "https://picsum.photos/250/200",
      ],
      camera: null,
      scene: null,
      renderer: null,
      codeUrl: 'https://kydww.museumcloud.com.cn/pub/Captcha/verify'
      // codeUrl: 'http://192.168.0.14/pub/Captcha/verify'
    };
  },
  created() {
    this.$nextTick(function () {
      this.galleryThumbsLunbo();
      this.galleryTopLunbo();
    });
  },
  mounted() {
    const router = useRouter();

    param.relic_id = router.currentRoute.value.query.collectid;
    this.collectDetail();

    let token = window.localStorage.getItem("token");
    let loginName = window.localStorage.getItem("loginName");
    console.log(token)
    if (loginName && token) {
      let user = {}
      user.name = loginName;

      user.token = token;
      this.setUserInfo(user);
    }

  },
  methods: {
    editForm15(index) {
      this.dialog15 = true;
      this.form15.data = this.list.relic_model[index];
      this.form15.data.index = index;

      setTimeout(() => {
        this.init();
        this.animate();
      }, 1000)


    },
    changeType(type) {
      this.loginType = type
    },
    noClose() {

    },
    openLogin() {
      if (this.token) {
        this.downloadImage();
      } else {
        this.clear();
        this.showLogin = true;
      }

    },
    closeLogin() {
      this.showLogin = false;
    },
    changeVerify() {
      this.codeUrl = `${this.codeUrl}?v=${new Date().getTime()}`
    },
    init() {
      const container = document.getElementById("container");
      this.camera = new THREE.PerspectiveCamera(
        45,
        800 / 600,
        1,
        2000
      );
      window.camera = this.camera;
      scene = new THREE.Scene();


      const ambientLight = new THREE.AmbientLight(0xcccccc, 0.4);
      scene.add(ambientLight);

      const pointLight = new THREE.PointLight(0xffffff, 0.8);
      this.camera.add(pointLight);
      scene.add(this.camera);

      const manager = new THREE.LoadingManager();
      manager.addHandler(/\.dds$/i, new DDSLoader());
      new MTLLoader(manager)
        // .load(this.form15.data.mtl_full, (materials) =>{
        .load('http://192.168.0.14/file/relic/1/model/1ceng.mtl', (materials) => {
          materials.preload();
          new OBJLoader(manager)
            .setMaterials(materials)
            // .load(this.form15.data.model_full,  (object)=> {
            .load('http://192.168.0.14/file/relic/1/model/1ceng.obj', (object) => {
              object.position.y = - 95;
              scene.add(object);
            }, (xhr) => {
              if (xhr.lengthComputable) {
                var percentComplete = (xhr.loaded / xhr.total) * 100;
                this.percentComplete = Math.round(percentComplete, 2) + "%";
                if (percentComplete < 100) {
                  this.showLoading = true
                } else {
                  this.showLoading = false
                }
              }
            },
              (error) => {
                this.showLoading = false
                console.error(error);
              });

        });
      this.renderer = new THREE.WebGLRenderer({ antialias: true });
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.setSize(800, 600);
      this.renderer.outputEncoding = THREE.sRGBEncoding;
      // container.innerHTML = this.renderer.domElement;
      container.appendChild(this.renderer.domElement);

      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.maxPolarAngle = Math.PI * 0.495;
      this.controls.update();
      window.addEventListener("resize", this.onWindowResize, false);

    },
    onWindowResize() {
      this.camera.aspect = 800 / 600;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(800, 600);
    },
    animate() {
      this.renderer.render(scene, this.camera);
      requestAnimationFrame(this.animate);
    },
    show() {
      this.$viewerApi({
        images: this.images,
      });
    },
    galleryTopLunbo() {
      new Swiper(".gallery_top", {
        spaceBetween: 0,
        slidesPerView: 1,
        // loop: true,
        autoplay: true,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        thumbs: {
          //thumbs组件专门用于制作带缩略图的swiper
          swiper: this.galleryThumbs,
          slideThumbActiveClass: "swiper-slide-thumb-active",
        },
      });
    },
    galleryThumbsLunbo() {
      this.galleryThumbs = new Swiper(".gallery_thumbs", {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        autoplay: true,
        spaceBetween: 0, //在slide之间设置距离（单位px）
        slidesPerView: "auto", //设置slider容器能够同时显示的slides数量
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        //loop: true, //设置为true 则开启loop模式
        freeMode: true, //默认为false，普通模式：slide滑动时只滑动一格
        loopedSlides: 5, //一般设置大于可视slide个数2个即可
        watchSlidesVisibility: true, //开启watchSlidesVisibility选项前需要先开启watchSlidesProgress
        watchSlidesProgress: true, //开启这个参数来计算每个slide的progress(进度、进程)
      });
    },
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const store = useStore();
    const list = ref([]);
    const router = useRouter();
    const config = computed(() => store.state.config);
    const name = ref("");
    const code = ref("");
    const password = ref("");
    const loginType = ref("1");
    const showLogin = ref(false);
    const token = ref("");
    let form4 = reactive({
      data: {},
    });

    let dialog4 = ref(false);
    let form5 = reactive({
      data: {},
    });

    let dialog5 = ref(false);

    let form6 = reactive({
      data: {},
    });

    let dialog6 = ref(false);

    let form8 = reactive({
      data: {},
    });

    let dialog8 = ref(false);
    let form9 = reactive({
      data: {},
    });

    let dialog9 = ref(false);

    let form10 = reactive({
      data: {},
    });

    let dialog10 = ref(false);

    let form11 = reactive({
      data: {},
    });

    let dialog11 = ref(false);

    let form12 = reactive({
      data: {},
    });

    let dialog12 = ref(false);

    let form15 = reactive({
      data: {},
    });

    let dialog15 = ref(false);


    const collectDetail = () => {
      proxy.$api.collectDetail(param).then((res) => {
        let data = res.data;
        if (data.relic_img && data.relic_img.length) {
          data.images = [];
          for (let i = 0; i < data.relic_img.length; i++) {
            data.images.push(data.relic_img.file);
          }
        }

        let item = data;

        if (item.year) {
          console.log(item.year);
          if (item.year.indexOf(",") != -1) {
            let arr = item.year.split(",");
            item.year = arr[arr.length - 1];
          }
        }
        store.commit("setConfig", data.conf);
        list.value = item;
        token.value = item.token;
        console.log(data);
      });
    };

    const handleEditRelic = () => {
      router.push({
        path: "edit",
        query: {
          redirect: router.currentRoute.fullPath,
          id: param.relic_id,
        },
      });
    };

    const editForm4 = (index) => {
      form4.data = list.value.relic_spread[index];
      form4.data.index = index;
      dialog4.value = true;
    };

    const editForm5 = (index) => {
      form5.data = list.value.relic_record[index];
      form5.data.index = index;
      dialog5.value = true;
    };

    const editForm6 = (index) => {
      form6.data = list.value.relic_authenticate[index];
      form6.data.index = index;
      dialog6.value = true;
    };

    const editForm8 = (index) => {
      form8.data = list.value.relic_damage[index];
      form8.data.index = index;
      dialog8.value = true;
    };

    const editForm9 = (index) => {
      form9.data = list.value.relic_repair[index];
      form9.data.index = index;
      dialog9.value = true;
    };

    const editForm10 = (index) => {
      form10.data = list.value.relic_upkeep[index];
      form10.data.index = index;
      dialog10.value = true;
    };

    const editForm11 = (index) => {
      form11.data = list.value.relic_move[index];
      form11.data.index = index;
      dialog11.value = true;
    };

    const editForm12 = (index) => {
      form12.data = list.value.relic_show[index];
      form12.data.index = index;
      dialog12.value = true;
    };

    // const editForm15 = (index) => {
    //   form15.data = list.value.relic_model[index];
    //   form15.data.index = index;
    //   dialog15.value = true;
    //   this.init();
    // };
    const setUserInfo = (user) => {

      console.log(user)
      store.commit("setUser", user);
    }

    const downWord = () => {
      proxy.$api.downWord({
        relic_id: param.relic_id
      }).then((res) => {
        let a = document.createElement('a');
        a.download = res.name;
        a.href = res.file;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
    };


    const downloadImage = () => {

      console.log({
        relic_id: list.value.down_img.id,
        file:list.value.down_img.file,
      })
      proxy.$api.collectDownload({
        relic_id: list.value.relic_id,
        file:list.value.down_img.file,
      }).then((res) => {
        // let a = document.createElement('a');
        // a.download = res.name;
        // a.href = res.file;
        // document.body.appendChild(a);
        // a.click();
        // document.body.removeChild(a);
        var elink = document.createElement('a');
        elink.download = list.value.relic_name +".jpg";
        elink.style.display = 'none';
        var blob = new Blob([res]);
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        document.body.removeChild(elink);
      });
    };

    const register = () => {
      var param = {
        name: name.value,
        pwd: password.value,
        cpatcha: code.value,
      };
      proxy.$api.register(param).then((res) => {
        let data = res;
        console.log(data);

        if (data.code !== "200") {
          ElNotification({
            type: "error",
            message: data.msg,
            duration: 2000,
          });
        } else {

          ElNotification({
            type: "success",
            message: '注册成功，请登录',
            duration: 2000,
          });

          loginType.value = '1';
          clear();


          // window.sessionStorage.setItem("token", data.data.token);
          // window.sessionStorage.setItem("loginName", data.data.name);
          // window.sessionStorage.setItem("userId", data.data.user_id);
          // window.sessionStorage.setItem("museumId", data.data.museum_id);
          // window.sessionStorage.setItem("museumName", data.data.museum_name);
          // user.name = data.data.name;
          // user.id = data.data.user_id;
          // user.token = data.data.token;
          // store.commit("setUser", user);

          // router.push({
          //   path: "collect",
          //   query: { redirect: router.currentRoute.fullPath },
          // });
        }

      });
    };

    const downImg = () => {




      var httpRequest = new XMLHttpRequest();

      httpRequest.responseType = 'arraybuffer';
      // httpRequest.setRequestHeader("Content-Type","application/x-www-form-urlencoded");
      httpRequest.open('GET', list.value.down_img.file, true);
      httpRequest.onload = function () {
        if (httpRequest.readyState == 4 && httpRequest.status == 200) {


          // let a = document.createElement('a');
          //   a.download = list.value.relic_name;
          //   a.href =  list.value.down_img.file;
          //   document.body.appendChild(a);
          //   a.click();
          //   document.body.removeChild(a);

          // let link = list.value.down_img.file;
          // let endType = link.split(".")[1];


          var content = httpRequest.response;
          var elink = document.createElement('a');
          elink.download = list.value.relic_name +".jpg";
          elink.style.display = 'none';
          var blob = new Blob([content]);

          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          document.body.removeChild(elink);
        }
      };
      httpRequest.send();



    };

    const clear = () => {
      name.value = '';
      code.value = '';
      password.value = '';
    }

    const login = () => {
      var param = {
        name: name.value,
        pwd: password.value,
        cpatcha: code.value,
      };
      proxy.$api.login(param).then((res) => {
        let data = res;
        console.log(data);

        if (data.code !== "200") {
          ElNotification({
            type: "error",
            message: data.msg,
            duration: 2000,
          });
        } else {
          // window.sessionStorage.setItem("token", data.data.token);
          // window.sessionStorage.setItem("loginName", data.data.name);
          window.localStorage.setItem("token", data.data.token);
          window.localStorage.setItem("loginName", data.data.name);
          ElNotification({
            type: "success",
            message: '登录成功',
            duration: 2000,
          });
          collectDetail();
          showLogin.value = false;
          // window.sessionStorage.setItem("userId", data.data.user_id);
          // window.sessionStorage.setItem("museumId", data.data.museum_id);
          // window.sessionStorage.setItem("museumName", data.data.museum_name);
          let user = {}
          user.name = data.data.name;

          user.token = data.data.token;
          store.commit("setUser", user);

          // router.push({
          //   path: "collect",
          //   query: { redirect: router.currentRoute.fullPath },
          // });
        }

      });
    };


    const state = computed(() => useStore().state.client);

    return {
      state,
      list,
      login,
      code,
      name,
      password,
      register,
      editForm4,
      dialog4,
      loginType,
      downImg,
      editForm5,
      dialog5,
      editForm6,
      dialog6,
      editForm8,
      dialog8,
      editForm9,
      dialog9,
      editForm10,
      dialog10,
      editForm11,
      dialog11,
      editForm12,
      dialog12,
      clear,
      config,
      // editForm15,
      dialog15,
      form4,
      form5,
      form6,
      form8,
      form9,
      form10,
      form11,
      form12,
      form15,
      downloadImage,
      showLogin,
      downWord,
      setUserInfo,
      token,
      collectDetail,
      handleEditRelic,
    };
  },
};
</script>

<style scoped>
@import "~@/assets/css/public.css";
@import "~@/assets/css/collectDeatil.css";

/deep/.el-table {
  background: transparent;
}

/deep/.el-table tr {
  background: transparent;
}

/deep/.el-table th.el-table__cell {
  background: transparent;
}

/deep/ .el-form-item {
  display: block;
}

.form-item {
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 10px));
  column-gap: 20px;
}


.gallery_top .swiper-slide {
  height: 500px;
}

/deep/ .gallery_topimg1 .el-image__inner {
  object-fit: contain;
}


.gallery_topimg_box {
  height: 80px;
}

.gallery_topimg {
  object-fit: contain;
  height: 100%;
  width: 100%;
}


/deep/ .el-form-item .el-form-item__label {
  display: block;
  height: auto;
  text-align: left;
  white-space: nowrap;
}

.audio {
  width: 260px;
  /* height: 100px; */
}

.video {
  width: 260px;
  height: 100px;
}

.video-cover {
  width: 100px;
  height: 100px;
}

.iframe {
  width: 800px;
  height: 600px;
  border: 0;
  /* background:#000; */
}


canvas {
  width: 800px;
  height: 600px;
}

.item-value {
  color: #333;
  font-weight: bold;
}


.detail-row {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.down-button {
  width: 150px;
  height: 48px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border: 1px solid #B4B4B4;
  line-height: 48px;
  text-align: center;
  margin: 0 auto;
  cursor: pointer;
}

.down-button::before {
  content: " ";
  background: url("../assets/images/icon_download.svg");
  background-size: 100% 100%;
  width: 13px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
}

.login-box {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, .65);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1000;
}

.login-head {
  width: 480px;
  height: 90px;
  border-radius: 10px 10px 0px 0px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 115px;
  font-size: 24px;
  color: #E8E0CB;
}

.head-item {
  cursor: pointer;
}

.login-body {
  width: 480px;
  background: #FFFFFF;
  border-radius: 0px 0px 10px 10px;
  opacity: 1;
  padding: 33px 50px;
}


.login-input {
  display: flex;

  justify-content: space-between;
}

.login-body input {
  width: 380px;
  height: 40px;
  border-radius: 2px 2px 2px 2px;
  opacity: 1;
  border: 1px solid #E7E7E7;
  margin-bottom: 18px;
  padding: 10px;

}

.login-button {
  width: 380px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: linear-gradient(180deg, #D4BC81 0%, #B4974D 100%);
  border-radius: 2px 2px 2px 2px;
  opacity: 1;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
}

.code-input {
  width: 262px !important;
  height: 40px;
}

.code-img {
  width: 109px;
  height: 40px;
  margin-left: 10px;
  cursor: pointer;
}

.login-button:hover {
  opacity: 0.8;
}

.active {
  position: relative;
  color: #fff;
}

.active::after {
  content: " ";
  width: 100%;
  height: 2px;
  background: #fff;
  display: inline-block;
  position: absolute;
  left: 0;
  bottom: -10px;
}
</style>
